<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="My Plays" subtitle="History of the library games you checked out or played." />

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no plays found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Game" />
				<app-table-column text="Checkout by" />
				<app-table-column align="center" text="Hours" />
				<app-table-column text="Checked Out" />
				<app-table-column text="Checked In" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.game.name" :subtext="item.game.version" />
					<app-table-cell-text :text="item.badge.name" :subtext="'#' + item.badge.reference" />
					<app-table-cell-text align="center" :text="item.hours" />
					<app-table-cell-text :text="item.date_out | localDate('h:mma')" :subtext="item.date_out | localDate('dddd')" />
					<app-table-cell-text :can-empty="true" :text="item.date_in | localDate('h:mma')" :subtext="item.date_in | localDate('dddd')" />

				</app-table-row>

			</app-table-body>

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			endpoint: 'convention/checkout/loans',
			live: 'convention/checkout/loans',
			layout: 'auto 300px 100px 100px 100px',
		}

	}

}

</script>

<style scoped>

</style>